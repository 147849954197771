import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnChanges
} from "@angular/core";
import {
  DomainService,
  Domain,
  LoaderService,
  LanguageService,
  StorageService, TEMP_KEYS,
  VOLATILE_KEYS,
  LABELS
} from "@services/public";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {Router } from "@angular/router";
import { WeeklyChallengeService } from "@pages/member/challenges/services/weekly-challenge.service";
import { GeneralModalComponent } from "@pages/member/general-modal/general-modal.component";
import { CarouselComponent } from "@components/carousel/carousel.component";
import { CarouselService } from "@services/member/carousel.service";
import { CardService } from "@services/member";
import { CongratulationsPopUpComponent } from "../congratulations-pop-up/congratulations-pop-up.component";
import { Subscription,lastValueFrom } from "rxjs";
import { ConfettiService } from "@services/member/confetti.service";
import { UserAgentScreenService } from "@services/member/user-agent-screen.service";
import { MemberAssessmentExternalCreateOrUpdateComponent } from "@pages/member/assessment";
import { version } from "environment";
import { SkillBuilderService } from "@services/member";

const PAGE_PATH: string = 'pages.member.dashboard.activities.challenges.page';

enum Icon {
  Read = "icon-Read-icon",
  Reflection = "icon-Reflection-icon",
  Video = "icon-Video-icon",
  What = "icon-What-You-Do",
  Decision = "icon-Practice-Decision-Making",
  Message = "icon-Manage-Message",
  Stress = "icon-Make-Stress-Plan",
  Inventory = "icon-Inventory-icon",
  Adventure = "icon-Go-Adventure",
  Journal = "icon-Did-Felt-Journal",
  Brain = "icon-Build-Positive-Brain",
  Assessment = "icon-Assessment-icon",
  Goal = "icon-Achieve-Goal",
  Abstract = "icon-Abstract-Thinking",
}

enum ChallenegeType {
  Skill_Builder = "skill-builder",
  Video = "video",
  Reading = "reading",
  Inventory = "inventory",
  Self_Assessment = "self-assessment",
  External_Assessment = "external-assessment",
  Reflection = "reflection",
}

@Component({
  selector: "app-activities",
  templateUrl: "./challenges.component.html",
  styleUrls: ["./challenges.component.scss"],
})
export class MemberDashboardActivitiesChallengesComponent implements OnInit, OnChanges{
  @ViewChild("template") template: TemplateRef<any>;
  carousel: CarouselComponent;
  modalRef?: BsModalRef;
  message?: string;
  count = 1;
  domains: Array<Domain> = [];
  WeeklyChallenge: any;
  mondayActivity: any = [];
  tuesdayActivity: any = [];
  wednesdayActivity: any = [];
  thursdayActivity: any = [];
  fridayActivity: any = [];
  sundayActivity: any = [];
  saturdayActivity: any = [];
  isOrientationWeek: boolean = false;
  number = 70;
  level: any;
  domainsName: any;
  score: any;
  domainLogo: any;
  badgeLog: any;
  description: any;
  info: any;
  skillStatus: any;
  assetsPath: string = "../../../../../assets/";
  private _subscriptions: Subscription = new Subscription();
  modalChallengeUUID: string = "";
  isFirstSelfAssessment: boolean = false;
  isCompleteVideo: boolean = false;
  fireworks: any;
  version: number = parseInt(version.split('.').slice(-1).toString());
  progress: any;

  // Page langauge.
  page: {[key: string]: string} = {
    title: '',
    badges: '',
    progress: '',
    missedOpportunities: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    challengeAlreadyComplete: ''
  }
  labels: {[key: string]: string} = {
    [LABELS.CLOSE]: '',
    [LABELS.COMPLETED]: '',
    [LABELS.LEVEL]: ''
  }

  constructor(
    public domainSvc: DomainService,
    public carouselSvc: CarouselService,
    private router: Router,
    private modalSvc: BsModalService,
    private weeklySvc: WeeklyChallengeService,
    private loaderSvc: LoaderService,
    private _languageSvc: LanguageService,
    private _storageSvc: StorageService,
    private confetSrvc: ConfettiService,
    private userScr: UserAgentScreenService,
    private _skillBuilderSvc: SkillBuilderService,
    private _cardSvc: CardService,
  ) {}

  ngOnChanges() {
    clearInterval(this.fireworks);
  }

  ngOnInit() {
    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => this.page[key] = value
          );
        }
      }
    );
    this._languageSvc.getLabels(this.labels);

    // Load domains
    this.domainSvc.getDomains().then((response) => {
      this.domains = response;
    });
    // this.onboardingComplete();
    this.loadWeeklyChallenges();
  }

  loadWeeklyChallenges() {
    let nextDomain;
    let currentDomain;
    const loader: unique symbol = Symbol();
    this.loaderSvc.addLoader(loader);
    this.domainsName = '';
    this.weeklySvc.getWeekly().subscribe((res) => {
      if (!res.noChallenges) {
        this.WeeklyChallenge = res;
        this.level = res.level;
        this.score = res.score;
        this.progress = `${this.score},${100}`
        if (this.WeeklyChallenge.domainUID === 0) {
          this.isOrientationWeek = true;
          const path: string = 'onboarding';
          this._languageSvc.get([path]).then(
            value => {
              this.description = value[path]?.challenge?.description || '';
              this.info = value[path]?.challenge?.info || '';
            });
          this.domainsName = "Orientation Week";
          this.badgeLog = 0;
          this.domainLogo = "assets/img/logo-jakapa.png";
          currentDomain={name:this.domainsName};
        } else {
          this.domainLogo = this.domainSvc.getLogo(
            this.WeeklyChallenge.domainUID
          );
          currentDomain = this.domains.find(
            (domain) => domain.uid === this.WeeklyChallenge.domainUID
          );
          if (currentDomain) {
            this._languageSvc
              .template(currentDomain["challenge"].description, {
                language: currentDomain.name.toLowerCase(),
                level: this.level,
              })
              .then((value) => {
                this.description = value;
              });
            this._languageSvc
              .template(currentDomain["challenge"].info, {
                level: this.level,
              })
              .then((value) => {
                this.info = value;
              });
          }

          this.isOrientationWeek = false;
          if (this.level <= 3) {
            this.badgeLog = 1;
          } else if (this.level >= 4 && this.level <= 6) {
            this.badgeLog = 2;
          } else if (this.level === 7) {
            this.badgeLog = 3;
          } else if (this.level === 8) {
            this.badgeLog = 4;
          } else if (this.level === 9) {
            this.badgeLog = 5;
          } else {
            this.badgeLog = 6;
          }
        }

        if (this.WeeklyChallenge.domainUID === 0) {
          const dayArray =  Array.from({ length: 5 }, () => []);
          this.lockOrientatio(this.WeeklyChallenge.days[0].challenges);
          this.WeeklyChallenge.days[0].challenges =  this.sortChallenges(
            this.loadchallenge(this.WeeklyChallenge.days[0].challenges));
          const challenges = this.WeeklyChallenge.days[0].challenges;

          const remainder = challenges.length % 4
          let day3 = 0;
          if (remainder !== 0) day3 = 4 - remainder;
          const day4 = (challenges.length - (day3 * 3)) / 4;
          let i = 0;
          let j = 0;
          for (let k = 0; k < day4; k++) {
            dayArray[i].push(...challenges.slice(j, j + 4));
            i++;
            j += 4;
          }
          for (let k = 0; k < day3; k++) {
            dayArray[i].push(...challenges.slice(j, j + 3));
            i++;
            j += 3;
          }
          this.mondayActivity = dayArray[0];
          this.tuesdayActivity = dayArray[1];
          this.wednesdayActivity = dayArray[2];
          this.thursdayActivity = dayArray[3];
          this.fridayActivity = dayArray[4];
        }
        else{
          this.WeeklyChallenge.days.map((ele) => {
            ele.challenges = this.loadchallenge(ele.challenges);
            if (ele.day === 1) {
              this.mondayActivity = this.sortChallenges(ele.challenges);
            } else if (ele.day === 2) {
              this.tuesdayActivity = this.sortChallenges(ele.challenges);
            } else if (ele.day === 3) {
              this.wednesdayActivity = this.sortChallenges(ele.challenges);
            } else if (ele.day === 4) {
              this.thursdayActivity = this.sortChallenges(ele.challenges);
            } else if (ele.day === 5) {
              this.fridayActivity = this.sortChallenges(ele.challenges);
            } else if (ele.day === 6) {
              this.saturdayActivity = this.sortChallenges(ele.challenges);
            } else {
              this.sundayActivity = this.sortChallenges(ele.challenges);
            }
          });
        }

        // Check every friday pop pup
        if (new Date().getDay() !== 5) {
          nextDomain = this.domains.find(
            (domain) => domain.uid === this.WeeklyChallenge.nextDomainUID
          );
          const popUp = this.fridayActivity.filter(
            (element) => element.status === "completed"
          );
          if (popUp.length === this.fridayActivity.length) {
            const everyFridayPopUp = JSON.parse(
              localStorage.getItem("everyFridayPopUp")
            );
            if ( everyFridayPopUp === null || everyFridayPopUp.domainUID
              !== this.WeeklyChallenge.domainUID) {
              let popUpData = {
                level: this.level,
                nextLevel: this.WeeklyChallenge.nextLevel,
                nextDomain: nextDomain.name,
                domains: currentDomain.name,
                title: "Well Done!",
              };
              localStorage.setItem(
                "everyFridayPopUp",
                JSON.stringify({
                  domainUID: this.WeeklyChallenge.domainUID,
                  isPopUpOpen: false,
                })
              );
              if (this.score >= 100){
                this.openCongratulationModal(popUpData);
              }
            }
          }
        }
      }
    });
    this.loaderSvc.removeLoader(loader);
  }

  sortChallenges(challenges): Array<any> {
    return challenges.sort((item1, item2) => {
      if ( item1.sort < item2.sort ) return -1;
      if ( item2.sort < item1.sort ) return 1;
      return 0
    })
  }

  async handleChallengeComplete(): Promise<void> {
    let uuid = this.modalChallengeUUID;
    if (uuid == "") return
    await lastValueFrom(this.weeklySvc.completeWeeklyChallenge(
      {uuid: this.modalChallengeUUID, timezone: "America/Chicago"}))
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalSvc.show(template, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  confirm(): void {
    this.message = "Confirmed!";
    this.modalRef?.hide();
  }

  decline(): void {
    this.message = "Declined!";
    this.modalRef?.hide();
  }

  loadchallenge(challenges) {
    challenges.map((challenge) => {
      let openWhenCompleted = [ChallenegeType.Reading,
        ChallenegeType.Video].indexOf(challenge.type) > -1;
        challenge.isDisabled =
        challenge.status === "completed" && !openWhenCompleted  ||
        challenge.status === "locked" ||
        challenge.status === "missed" && !openWhenCompleted
          ? true
          : false;
      if (challenge.type === ChallenegeType.Skill_Builder) {
        this.skillBuildersIcon(challenge);
      } else if (challenge.type === ChallenegeType.Video) {
        challenge.img = Icon.Video;
      } else if (challenge.type === ChallenegeType.Reading) {
        challenge.img = Icon.Read;
      } else if (
        challenge.type === ChallenegeType.Self_Assessment ||
        challenge.type === ChallenegeType.External_Assessment
      ) {
        challenge.img = Icon.Assessment;
      } else if (challenge.type === ChallenegeType.Inventory) {
        challenge.img = Icon.Inventory;
      } else {
        challenge.img = Icon.Reflection;
      }

    });

    //

    return challenges;
  }

  skillBuildersIcon(challenge) {
    switch (challenge.skillBuilderType) {
      case "stress":
        challenge.img = Icon.Stress;
        break;
      case "brain":
        challenge.img = Icon.Brain;
        break;
      case "goal":
        challenge.img = Icon.Goal;
        break;
      case "journal":
        challenge.img = Icon.Journal;
        break;
      case "decision":
        challenge.img = Icon.Decision;
        break;
      case "message":
        challenge.img = Icon.Message;
        break;
      case "abstract":
        challenge.img = Icon.Abstract;
        break;
      case "adventure":
        challenge.img = Icon.Adventure;
        break;
      default:
        challenge.img = Icon.What;
    }
  }

  activityAction(event) {
    let weeklyData: any = {
      weeklyChallenge: true,
      domainUUID: this.WeeklyChallenge.domainUID,
      uuid: event.uuid,
      logUUID: event.logUUID
    };
    localStorage.setItem("weeklyActivities", JSON.stringify(weeklyData));
    if (event.type === ChallenegeType.Skill_Builder) {
      weeklyData.goalPlanUUID = event.goalPlanUUID;
      weeklyData.doTomorrow = event.doTomorrow;
      weeklyData.noPreviousLog = event.noPreviousLog;
      weeklyData.logUUID = event.logUUID;
      weeklyData.skillStatus = event.skillStatus;
      localStorage.setItem("weeklyActivities", JSON.stringify(weeklyData));
      if (event.dailyCompleted) {
        this.weeklySvc
          .completeWeeklyChallenge({ uuid: event.uuid })
          .subscribe();
        this.openModal(this.template);
      }
      else {
        if (!!event.placement) {
          const path: string = `domains.${
            this.WeeklyChallenge.domainUID}.challenge.reading.cycle.${
              this.WeeklyChallenge.level}.${event.weekday}.${event.placement}`;
          this._languageSvc.get([path]).then(value => {
            const slides = Object.values(value[`${path}`]);
            if (!!slides) {
              this.loadSlides(path, event.skillBuilderType, event);
              this.carouselSvc.onClose.subscribe(data => {
                if (data === "finished") {
                  this.router.navigate(['member/skill-builder', event.skillBuilderType]);
                }
              });
            } else {
              this.router.navigate(['member/skill-builder', event.skillBuilderType]);
            }
          });
        } else {
          this.router.navigate(['member/skill-builder', event.skillBuilderType]);
        }
      }
    } else if (event.type === ChallenegeType.Video) {
      this.isCompleteVideo =  this.WeeklyChallenge.days[0].challenges
      .filter(challenge => challenge.type === event.type)
      .every(challenge => event.sort >= challenge.sort);
      if(this.isCompleteVideo){
        this.fireworks = this.confetSrvc.fireworks(
          undefined, undefined, undefined, undefined, 5000);
        if (this.userScr.isSmallScreen() || this.userScr.isMobile()) {
          this.router.navigate([`member/dashboard/rewards`]);
        }
      }
      this.modalRef = this.modalSvc.show(GeneralModalComponent, {
        initialState: {
          modalInfo: {
            title: "Video",
            subTitle: "",
            message: "",
            linkText: "",
            linkDestination: `${event.link}`,
            videoSrc: `${event.link}`,
            transcript: `${event.transcript}`
          },
          challengeID: event.uuid,
          status: event.status
        },
        class: 'centered-all'
      });
      this.modalChallengeUUID = event.uuid;
      // complete challenges when modal is closed by clicking outside the modal
        this._subscriptions.add(this.modalRef.onHidden.subscribe(() => {
          clearInterval(this.fireworks);
          if (event.status == "open"){
             this.handleChallengeComplete().then(() => this.loadWeeklyChallenges());
          }
        }));
    } else if (event.type === ChallenegeType.Reading) {
      this.modalChallengeUUID = event.uuid;
      if (this.isOrientationWeek) {
        const path = `onboarding.challenge.reading.${event.placement}`;
        this.loadSlides(path, 'reading', event);
      } else {
        const path = `domains.${this.WeeklyChallenge.domainUID
        }.challenge.reading.cycle.${this.WeeklyChallenge.level
        }.${event.weekday}.${event.placement}`;
        this.loadSlides(path,'reading', event);
      }
    } else if (event.type === ChallenegeType.Self_Assessment) {
      if (this.isOrientationWeek) {
        this.isFirstSelfAssessment = this.WeeklyChallenge.days[0].challenges
        .filter((challenge: { type: any; }) => challenge.type === event.type)
        .reduce((min: number, challenge: { sort: number; }) => Math.min(
          min, challenge.sort), Infinity) === event.sort;
      }
      this._storageSvc.setTempStorage(
        TEMP_KEYS.IS_FIRST_SELF_ASSESSMENT, this.isFirstSelfAssessment);
        console.log(event.assessmentUUID);
        
      this.router.navigate(["member/assessment/self", event.assessmentUUID]);
    } else if (event.type === ChallenegeType.External_Assessment) {
      localStorage.removeItem('weeklyActivities');
      this.router.navigate(["member/assessment/external/list"]);
      this.modalSvc.show(
        MemberAssessmentExternalCreateOrUpdateComponent,
        {
          class: "modal-md modal-dialog-centered",
          backdrop: "static",
          keyboard: true,
        }
      );
    } else if (event.type === ChallenegeType.Inventory) {
      this.router.navigate(["member/inventory"]);
    } else {
      this._storageSvc.setVolatileStorage(
        VOLATILE_KEYS.DOMAIN_UID, this.WeeklyChallenge.domainUID);
      this.router.navigate(['member/skill-builder/reflection']);
    }
  }

  openCongratulationModal(info) {
    this.modalRef = this.modalSvc.show(CongratulationsPopUpComponent, {
      initialState: {
        popUpInfo: info
      },
      class: "modal-sm modal-dialog-centered",
    });
    this.modalRef.onHidden.subscribe(() => {
      localStorage.setItem(
        "everyFridayPopUp",
        JSON.stringify({
          domainUID: this.WeeklyChallenge.domainUID,
          isPopUpOpen: true,
        })
      );
    });
  }

  lockOrientatio(challenges) {
    let unlocked = false;
    for (let idx = 0; idx < challenges.length; idx++) {
      for (let p in challenges[idx]) {
        if (String(p) === "status") {
          if (idx === 0) {
            if (challenges[idx][p] !== "completed") {
              challenges[idx][p] = "open";
              unlocked = true;
            }
          } else {
            if (
              challenges[idx - 1] &&
              challenges[idx - 1][p] === 'completed' &&
              challenges[idx][p] === 'open' &&
              !unlocked
            ) {
              unlocked = true;
            } else if (challenges[idx][p] !== "completed") {
              challenges[idx][p] = "locked";
            }
          }
        }
      }
    }
  }

  loadSlides(path: string, type: string, event?: any): void {
    this._languageSvc.get([path]).then(value => {
      const slides = Object.values(value[`${path}`]);
      if (!!slides) {
        if (!this.carouselSvc.carouselCreated) {
          this.carousel = this.carouselSvc.createComponent();
        }
        this.carousel.slides = slides;
        this.carousel.carouselInfo = {
          cardType: 'reading',
          readingType: type,
          domain: `_${this.WeeklyChallenge.domainUID}`,
        };
        const cardSubscription = this._cardSvc.closeEvent$.subscribe(value => {
          if (!!value) {
            this.carousel.showCarousel();
          }
        });
        this.carousel.showCarousel();
        const carouselSubscription = this.carouselSvc.onClose.subscribe(data => {
          if (data === "finished") {
            carouselSubscription.unsubscribe();
            cardSubscription.unsubscribe();
            if (type === 'reading') {
              if (event.status == "open") {
                this.handleChallengeComplete().then(() => this.loadWeeklyChallenges());
              }
            }
            else {
              this.router.navigate(['member/skill-builder', event.skillBuilderType]);
            }
            
          }
        });
      }
    });
  }
}

